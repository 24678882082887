@mixin d_flx($align: center, $justy: flex-start, $gap: 0) {
  display: flex;
  align-items: $align;
  justify-content: $justy;
  flex-wrap: wrap;
  gap: $gap;
}
@mixin img_contain($object: contain) {
  width: 100%;
  height: 100%;
  object-fit: $object;
}
